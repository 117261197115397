import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VProgressLinear,{attrs:{"active":_vm.loadingTela,"color":"red","indeterminate":"","absolute":"","rounded":"","height":"5"}}),_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogSucesso),callback:function ($$v) {_vm.dialogSucesso=$$v},expression:"dialogSucesso"}},[_c(VCard,[_c(VCardTitle),_c(VCardText,[_c(VContainer,[_c(VCardText,[_c('strong',{staticClass:"text-h6"},[_vm._v("Parabéns !")]),_c('br'),_c('p',[_vm._v(" Assim que realizar seu pagamento envie uma cópia de seu comprovante com Nome Completo para WhatsApp: +55 41 99802-6710. ")]),_c('p',[_vm._v(" Guarde seu Nome de Usuário e Senha em um lugar seguro, estes serão utilizados para acesso à Área de Usuário, onde ali estará exposto seu Curriculum Vitae e Artigos a muitas empresas. ")]),_c('small',[_vm._v("Caso tenha algum problema ou duvida envie um email para: "+_vm._s(_vm.contato.email)+" ou nos comunique pelo whatsapp: +55 41 99802-6710")])])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","disabled":_vm.desabilitar},on:{"click":function($event){_vm.dialogSucesso = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }