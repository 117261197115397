import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VProgressLinear,{attrs:{"active":_vm.loading,"color":"red","indeterminate":"","rounded":"","absolute":"","height":"5"}}),_c(VCard,{staticStyle:{"margin-bottom":"10px","margin-top":"20px"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.ProdutoCarregado.codProduto)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.ProdutoCarregado.descricao)}})],1)],1)],1),_c(VCard,{attrs:{"color":"basil","flat":""}},_vm._l((_vm.precos),function(i){return _c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valorSelecionado),expression:"valorSelecionado"}],attrs:{"type":"radio","id":i.id,"name":"precoSelect"},domProps:{"value":i.id,"checked":i.id == _vm.valorSelecionado,"checked":_vm._q(_vm.valorSelecionado,i.id)},on:{"change":function($event){_vm.valorSelecionado=i.id}}}),_c('label',{staticStyle:{"margin-left":"10px","font-weight":"bold","color":"grey"}},[_vm._v(_vm._s(i.valorTela)+" - "+_vm._s(i.nickname))])])}),0),_c(VBtn,{staticClass:"mr-1",staticStyle:{"color":"white","margin-top":"20px"},attrs:{"color":"red"},on:{"click":function($event){return _vm.salvarPreco(_vm.valorSelecionado)}}},[_vm._v(" Salvar ")]),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Fechar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.msgSnack)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }