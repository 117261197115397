import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticStyle:{"padding-top":"20px"}},[_c(VTabs,{staticStyle:{"margin-left":"30px"},attrs:{"bg-color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v("Nova Página")]),_c(VTab,[_vm._v("Deletar Página")])],1),_c(VCardText,[_c(VWindow,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VWindowItem,[_c('NovaPagina')],1),_c(VWindowItem,[_c('RemoverPagina')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }