import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VFooter,{attrs:{"color":"red","dark":"","app":"","absolute":""}},[_c(VContainer,[_c(VRow,{attrs:{"align":"start"}},[_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c('h4',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" CEDEPPE ")]),_c('p',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.contato.email)),_c('br'),_vm._v(" "+_vm._s(_vm.contato.whatsapp)+" ")])]),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VList,{attrs:{"flat":"","color":"transparent"}},[_c(VSubheader,{staticClass:"text-h6",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.ir('Home?modelo=comofunciona')}}},[_vm._v("Associar")]),_c(VListItemGroup,{attrs:{"color":"primary"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VList,{attrs:{"flat":"","color":"transparent"}},[_c(VSubheader,{staticClass:"text-h6",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.ir('Treinamentos')}}},[_vm._v("Treinamentos")]),_c(VListItemGroup,{attrs:{"color":"primary"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c('div',[_c('h5',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" Assine nosso NewsLetter ")]),_c('p',{staticClass:"text-body-1"},[_vm._v(" Assine nosso newsLetter e fique por dentro de tudo no CEDEPPE ")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"8"}},[_c(VTextField,{attrs:{"label":"email","solo":"","light":"","hide-details":""}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VBtn,{attrs:{"large":"","block":"","color":"secondary"}},[_vm._v(" Assinar ")])],1)],1)],1)],1),_c('div',{staticClass:"text-center mt-6"},[_vm._v(" Centro de Desenvolvimento Pessoal e Profissional de Executivos. ")]),_c('div',{staticClass:"text-center mt-3"},[_vm._v(" Copyright © "),_c('a',{staticClass:"white--text",attrs:{"href":"https://heroui.net","target":"_blank","rel":"noopener noreferrer"}}),_vm._v(" "+_vm._s(new Date().getFullYear())+". Todos os direitos reservados. ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }