<template>
  <div>
    <loadingTela v-if="carregando"/>
    <v-row>
      <v-col cols="12" lg="12">
        <v-card>
          <v-img
            src="../assets/index.png"
            gradient="to top, rgba(25,32,72,.7), rgba(25,32,72,.0)"
            style="width: 100% !important; height: 500px"
            dark
          >

            <v-card-text class="fill-height d-flex align-end">
              <v-row class="flex-column">
                <v-col>
                  <v-btn color="red"  @click="pedirCodigo()">Associar</v-btn>
                </v-col>
                <v-col cols="12" md="10" lg="8" xl="7">
                  <h2 class="text-h3 py-3" style="line-height: 1.2">
                    BEM-VINDO AO CEDEPPE
                  </h2>
                </v-col>

              </v-row>
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="9" v-if="modelo != null">
        <v-card  style="padding: 20px">
          <v-card-text>
             <strong class="text-h6">{{infosModelo != null ? infosModelo.TituloTexto : ''}}</strong>
              <p class="card-text" v-html="infosModelo != null ? infosModelo.Descricao : ''" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="9" v-if="modelo == null">
        <v-card  style="padding: 20px">
          <div style="padding-left: 20px; padding-bottom: 20px;">
            <v-btn color="red" style="color: white; padding: 20px;" @click="ir('/Home?modelo=comofunciona')">Como Funciona?</v-btn>
          </div>
          <v-card-text>
              <h4 class="text-h6 font-weight-medium pb-4">Cursos Abertos:</h4>
              <div v-for="i in infosModelo != null ? infosModelo.Descricao.filter(x => x.Tipo == 4) : []">
                * <a class="card-text" @click="ir(i.modelo)">{{i.Titulo}}</a></br>
              </div>
              </br>
              <h4 class="text-h6 font-weight-medium pb-4">Visite nossos núcleos:</h4>
              <h3 class="font-weight-heavy pb-4" v-if="infosModelo != null && infosModelo.Descricao.filter(x => x.Tipo == 1).length > 0">
                Lista de Oportunidades para Profissionais
              </h3>
              <div v-for="i in infosModelo != null ? infosModelo.Descricao.filter(x => x.Tipo == 1) : []">
                <a class="card-text" @click="ir(i.modelo)">{{i.Titulo + ' - ' + i.modelo }}</a>
              </div>
              </br>
              <h3 class="font-weight-heavy pb-4" v-if="infosModelo != null && infosModelo.Descricao.filter(x => x.Tipo == 2).length > 0">
                Lista de Oportunidades para Empresas
              </h3>
              <div v-for="i in infosModelo != null ? infosModelo.Descricao.filter(x => x.Tipo == 2) : []">
                <a class="card-text" @click="ir(i.modelo)">{{i.Titulo + ' - ' + i.modelo}}</a>
              </div>
              </br>
              <h3 class="font-weight-heavy pb-4" v-if="infosModelo != null && infosModelo.Descricao.filter(x => x.Tipo == 3 && x.Regiao == 1).length > 0">
                Regionais Nacionais
              </h3>
              <div v-for="i in infosModelo != null ? infosModelo.Descricao.filter(x => x.Tipo == 3 && x.Regiao == 1) : []">
                <a class="card-text" @click="ir(i.modelo)">{{i.Titulo + ' - ' + i.modelo}}</a>
              </div>
              </br>
              <h3 class="font-weight-heavy pb-4" v-if="infosModelo != null && infosModelo.Descricao.filter(x => x.Tipo == 3 && x.Regiao == 2).length > 0">
                Regionais Internacionais
              </h3>
              <div v-for="i in infosModelo != null ? infosModelo.Descricao.filter(x => x.Tipo == 3 && x.Regiao == 2) : []">
                <a class="card-text" @click="ir(i.modelo)">{{i.Titulo + ' - ' + i.modelo}}</a>
              </div>
          </v-card-text>
        </v-card>
      </v-col>
       <v-col cols="12" lg="3">
        <div>
        </div>
        <div>
          <SideBar />
        </div>
      </v-col>
     </v-row>
  </div>
</template>

<script>
  import main from '@/assets/scripts/main.js';
  export default {
    name: 'Home',
    components: {
      loadingTela: () => import("@/components/layout/loadingTela"),
      SideBar: () => import("@/components/layout/sidebar"),
    },
    data: () => ({
      fotoCapa: null,
      modelo: null,
      infosModelo: {
        FotoCapa: '../assets/acordo.png'
      },
      carregando: false
    }),
    async created(){
      this.carregando = true;
      const params = new URLSearchParams(window.location.search)
      const sorter = (a,b) => {
        if (a.Titulo.localeCompare(b.Titulo) === 0)
          return a.modelo.localeCompare(b.modelo)
        else
          return a.Titulo.localeCompare(b.Titulo)
      }
      if(params.get('modelo') != null){
        localStorage.setItem('origem', params.get('modelo'));
        this.modelo = params.get('modelo');
        var req = {
          modelo: this.modelo
        }
        var resp = await main.postApi(req, "paginas/pegaInfosPagina");
        var retorno = await resp.json();
        if(retorno.sucesso){
          // Sort response by description
          console.log(retorno.corpo)
          var Descriptions = retorno.corpo.sort(sorter);
          this.infosModelo = Descriptions
          console.log(this.infosModelo.Descricao.filter(x => x.Tipo == 1).sort(sorter))
        }
        this.carregando = false;
      }
      else{
        var req = {
          modelo: null
        }
        var resp = await main.postApi(req, "paginas/pegaInfosPagina");
        var retorno = await resp.json();
        if(retorno.sucesso){
          // Sort response by description
          console.log(retorno.corpo)
          var Descriptions = retorno.corpo.sort(sorter);
          this.infosModelo = {
            Titulo: 'Bem-vindo ao CEDEPPE',
            Descricao: Descriptions,
            Cordenadores: []
          }
          console.log(this.infosModelo.Descricao.filter(x => x.Tipo == 1).sort(sorter))
          console.log(this.infosModelo)
        }
        this.carregando = false;
      }
    },
    methods: {
      dataUrl(foto){
        if(this.modelo != null){
          return main.dataUrl(foto, 0)
        }
        else{
          return main.dataUrl(foto, 1)
        }
      },
      pedirCodigo(){
       this.$router.push('Pagamento?tipo=Associacao')
      },
      ir(caminho){
        window.location.assign("https://cedeppe.global/" + caminho);
      }
  }
  }
</script>
