import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"persistent":"","width":"1200","height":"1200"},model:{value:(_vm.dialogRemovePage),callback:function ($$v) {_vm.dialogRemovePage=$$v},expression:"dialogRemovePage"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Remover Página")])]),_c(VCardText,[_c(VContainer,[_c('h2',[_vm._v(_vm._s(_vm.pageToBeRemoved))]),_c('h3',[_vm._v("Têm certeza que deseja remover página '"+_vm._s(_vm.pageToBeRemoved)+"'? Essa ação é irreversível.")]),_c('h3',[_vm._v("URL: \\"+_vm._s(_vm.modeloToBeRemoved))])])],1),_c(VCardActions,[_c(VBtn,{staticStyle:{"margin":"5px"},attrs:{"variant":"tonal"},on:{"click":function($event){return _vm.cancelRemove()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticStyle:{"color":"white","margin":"5px"},attrs:{"color":"red","variant":"tonal"},on:{"click":function($event){return _vm.removePage()}}},[_vm._v(" Confirmar ")])],1)],1)],1),_vm._l((_vm.paginas),function(pagina){return _c(VListItem,{key:pagina.id},[_c(VListItemContent,[_c(VRow,[_c(VCol,{staticStyle:{"text-align":"left","margin-left":"20px"}},[_c(VListItemTitle,{staticStyle:{"font-weight":"bold","font-size":"18"}},[_c('h3',[_vm._v(_vm._s(pagina.Titulo))])]),_c(VListItemSubtitle,{staticStyle:{"font-weight":"bold","font-size":"18"}},[_c('h3',[_vm._v("URL: \\"+_vm._s(pagina.modelo))])])],1),_c(VCol,{staticStyle:{"text-align":"right"}},[_c(VBtn,{staticStyle:{"margin":"5px"},attrs:{"variant":"tonal"},on:{"click":function($event){return _vm.selectToBeRemoved(pagina)}}},[_vm._v(" Remover ")])],1)],1),_c(VDivider,{staticStyle:{"margin":"10px"}})],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }