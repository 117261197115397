import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.carregando)?_c('loadingTela'):_vm._e(),_c(VCard,{staticClass:"mx-auto my-12",staticStyle:{"min-height":"300px"},attrs:{"loading":_vm.loading,"max-width":"900"}},[_c('template',{slot:"progress"},[_c(VProgressLinear,{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c(VAvatar,{staticStyle:{"width":"200px","height":"200px","float":"right","margin":"20px"},attrs:{"color":"red"}},[_c(VImg,{attrs:{"src":_vm.dataUrl(_vm.infosModelo.FotoPerfil)}})],1),_c(VCardTitle,[_vm._v(_vm._s(_vm.infosModelo.Nome))]),_c(VCardText,[_c('div',[_vm._v(" "+_vm._s(_vm.infosModelo.Email)+" ")]),_c('div',[_vm._v(" Cupom: "+_vm._s(_vm.infosModelo.Cupom)+" ")]),_c(VCardText,[_c('p',{staticClass:"card-text",domProps:{"innerHTML":_vm._s(_vm.infosModelo != null ? _vm.infosModelo.Descricao : '')}})])],1)],2),_c(VDataTable,{staticClass:"elevation-1",staticStyle:{"max-width":"900px","margin":"auto"},attrs:{"headers":_vm.headers,"items":_vm.desserts,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VTextField,{staticClass:"mx-4",attrs:{"label":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.selecionar(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }