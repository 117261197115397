import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center my-3"},[_vm._v(" Loading products... ")]):_c('div',[_c(VList,_vm._l((_vm.products),function(product){return _c(VListItem,{key:product.Modelo},[_c(VListItemContent,[_c(VListItemTitle,[_c('h4',[_vm._v(_vm._s(product.Nome))])]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","margin":"1em"}},[_c('div',{staticStyle:{"width":"30%"}},[_c('label',[_vm._v("Preco Total")]),_c(VTextField,{attrs:{"type":"number","placeholder":"Enter new PrecoTotal"},model:{value:(product.PrecoTotal),callback:function ($$v) {_vm.$set(product, "PrecoTotal", $$v)},expression:"product.PrecoTotal"}})],1),_c('div',{staticStyle:{"width":"30%"}},[_c('label',[_vm._v("Preco Pix")]),_c(VTextField,{attrs:{"type":"number","placeholder":"Enter new PrecoPix"},model:{value:(product.PrecoPix),callback:function ($$v) {_vm.$set(product, "PrecoPix", $$v)},expression:"product.PrecoPix"}})],1),_c('div',{staticStyle:{"width":"30%"}},[_c('label',[_vm._v("Preco Ted")]),_c(VTextField,{attrs:{"type":"number","placeholder":"Enter new PrecoTed"},model:{value:(product.PrecoTed),callback:function ($$v) {_vm.$set(product, "PrecoTed", $$v)},expression:"product.PrecoTed"}})],1)])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"color":"red"},on:{"click":function($event){return _vm.confirmUpdate(product)}}},[_vm._v("Salvar")])],1)],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }