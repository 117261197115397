<template>
  <div>
    <v-app-bar v-if="!$vuetify.breakpoint.smAndUp" style="height: fit-content;">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        style="color: black;"
      />
      <v-toolbar-title style="text-align: left; width: 300px;">
        <v-img width="20%" src="@/assets/logo3.png" style="float:left; margin-right: 20px;"></v-img>
        <h2 style="color: #ff0000 !important; text-emphasis: true; margin-left: 20px">CEDEPPE</h2>
      </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-if="!$vuetify.breakpoint.smAndUp"
      v-model="drawer"
      location="left"
      temporary
      app
      color="white"
      dark
    >
      <v-list nav color="white">
        <v-list-item
          v-for="(item, i) in barItens"
          :key="i"
          link
          :to="item.to"
          :href="item.href"
          :target="item.target"
        >
          <v-list-item-content style="background-color: lightgray; border-radius: 5px;">
            <v-list-item-title style="color: black; text-align:center"> {{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="$vuetify.breakpoint.smAndUp"
      app
      flat
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="white"
      elevate-on-scroll
    >
      <v-container fluid :class="{ 'px-0': !$vuetify.breakpoint.smAndUp }">
        <v-row

          no-gutters
          align="left"
          justify="center"
        >
          <v-col cols="2" class="d-flex align-center">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="!$vuetify.breakpoint.smAndUp"/>
            <v-toolbar-title style="cursor: pointer" class="font-weight-bold text-h5 primary--text" @click="$router.push('/Home?modelo=comofunciona')">
              <v-img width="30%" src="@/assets/logo3.png" style="float:left;"></v-img>
              <span style="color: #ff0000 !important; margin-left: 10px">CEDEPPE</span>
            </v-toolbar-title>
          </v-col>
          <v-col cols="10" class="text-center">
            <v-btn
              v-for="(item, i) in barItens"
              :key="i"
              text
              class="ml-3 text-capitalize"
              :to="item.to"
              :href="item.href"
              exact-active-class="accent--text"
              exact
              >{{ item.title }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { mdiDownload, mdiHeadphones } from '@mdi/js';

export default {
  name: 'AppBar',
  data: () => ({
    drawer: null,
    icons: { mdiDownload, mdiHeadphones },
    barItens: [
      {
        title: "Home",
        to: "/Index",
      },
      {
        title: "Associando",
        to: "/Home?modelo=comofunciona",
      },
      {
        title: "Próximas Atividades",
        to: "/Home?modelo=eventosvip",
      },
      {
        title: "Dr. Roberto Cysne",
        href: "https://firebasestorage.googleapis.com/v0/b/cedeppe-web.appspot.com/o/Professor%20Dr.%20Roberto%20Cysne.pdf?alt=media&token=42619ea3-2762-40f8-b875-78f8c81aea83&_gl=1*9kyqmi*_ga*MjgxMzgxNTE5LjE2OTY5MzE5MTI.*_ga_CW55HF8NVT*MTY5NzEzMTk5MS4yLjEuMTY5NzEzMjAzNy4xNC4wLjA",
      },
      {
        title: "Portfolio",
        href: "https://firebasestorage.googleapis.com/v0/b/cedeppe-web.appspot.com/o/PORTFOLIO%202024.pdf?alt=media&token=10b9bd7c-7706-4256-8833-da4d053f5b00",
      },
      {
        title: "RadioWeb 26000",
        href: "https://www.radioweb26000.com.br",
      },
      {
        title: "Área do Usuário",
        to: "/Usuario",
      },
    ],
  }),
};
</script>