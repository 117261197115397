import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"color":"basil","flat":""}},[_c(VCard,[_c(VProgressLinear,{attrs:{"active":_vm.loading,"color":"red","indeterminate":"","rounded":"","absolute":"","height":"5"}}),_c(VDataTable,{staticClass:"elevation-1",attrs:{"locale":"pt-Br","headers":_vm.headers,"items":_vm.items,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VSpacer),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,{staticStyle:{"font-size":"15px"}},[_vm._v("Deseja deletar essa despeza?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Deletar")]),_c(VSpacer)],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }