import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VProgressLinear,{attrs:{"active":_vm.loading,"color":"red","indeterminate":"","rounded":"","height":"5"}}),_c(VCard,{staticStyle:{"padding":"15px"}},[_c('div',[_c('h4',{staticClass:"text-h6 font-weight-medium pb-4"},[_vm._v("Últimos Artigos")]),_c('div',_vm._l((_vm.artigos),function(i){return _c(VRow,{key:i.Titulo},[_c(VCol,{attrs:{"cols":"12","lg":"12"},on:{"click":function($event){return _vm.irArtigo(i.Id)}}},[_c('div',{staticStyle:{"margin-left":"10px"}},[_c('h5',{staticClass:"text-h8 font-weight-bold black--text;",staticStyle:{"cursor":"pointer"}},[_c('router-link',{staticStyle:{"background-color":"#ffaa00","padding":"3px","color":"white"},attrs:{"to":'/Artigo?id='+ i.Id}},[_vm._v(" "+_vm._s(i.Titulo)+" ")])],1),_c('h6',{staticClass:"color: #ffaa00"},[_vm._v(_vm._s(i.Nome)+" em "+_vm._s(_vm.dataAtualFormatada(i.DtCriacao)))])])]),_c(VDivider)],1)}),1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }